<template>
  <div class="luta_continua_background">
    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center flex-column align-center">
        <div class="d-flex align-self-start pt-2 pb-2">
          <Breadcrumb class="conteudos_tematicos_section_bc-color">
            <template v-slot:breadcrumbLinks>
              <router-link
                tag="p"
                :to="{ name: link.href }"
                v-for="(link, idx) in breadcrumbItems"
                :key="idx"
              >
                {{ link.text }}
              </router-link>
            </template>
          </Breadcrumb>
        </div>
        <div class="d-flex align-self-center justify-center align-center">
          <img
            width="40%"
            src="../../assets/conteudos_luta_continua_title.png"
            alt=""
          />
        </div>
      </v-row>
      <v-row class="d-flex justify-md-space-around align-center pt-5 pb-5 ovx">
        <v-col
          cols="4"
          md="2"
          class="d-flex justify-center align-center text-center"
        >
          <router-link :to="{ name: 'conteudos_tematicos_luta_continua' }">
            Luta pelo reassentamento
          </router-link>
        </v-col>
        <v-col
          cols="4"
          md="2"
          class="d-flex justify-center align-center text-center"
        >
          <router-link :to="{ name: 'conteudos_tematicos_luta_musica' }">
            Música A LUTA
          </router-link>
        </v-col>
        <v-col
          cols="4"
          md="2"
          class="d-flex justify-center align-center text-center"
        >
          <router-link :to="{ name: 'conteudos_tematicos_luta_crime' }">
            Não prescrição do crime
          </router-link>
        </v-col>
        <v-col
          cols="4"
          md="2"
          class="d-flex justify-center align-center text-center"
        >
          <router-link :to="{ name: 'conteudos_tematicos_luta_reparacao' }">
            Reparação Integral
          </router-link>
        </v-col>
      </v-row>
    </section>
    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  A paródia “A luta” foi criada para homenagear a história e
                  força dos atingidos e atingidas pelo rompimento da barragem de
                  Fundão, em Mariana. As atividades desenvolvidas pela
                  Assessoria Técnica Independente (ATI) possibilitam aos
                  assessores e assessoras um olhar crítico e aguçado sobre o
                  processo reparatório enfrentado por todos que tiveram a vida
                  modificada de alguma forma pela tragédia que não começou nem
                  se encerrou em 2015. E é com base nesse processo, tão
                  angustiante, aflitivo, e muitas vezes frustrante, que a
                  resistência pode surgir, seja por meio do esforço diário em
                  não banalizar as violências sofridas, seja por meio da arte,
                  que tem o poder de ressignificar e levar a realidade a outro
                  patamar. Esta paródia da canção "A Casa". de Vinicius de
                  Moraes e Sérgio Bardotti, é uma composição de Teresa Chaves
                  Silva, cuja gravação de áudio foi produzida por Saulo Henrique
                  Pena Fagundes com a colaboração da compositora e de Laura
                  Lelis Pascoal, todos da equipe de Assessoria Jurídica, e com o
                  apoio de João Gustavo. Conta com a participação especialíssima
                  de Débora Letícia no vocal principal, atingida de Paracatu de
                  Baixo, representando a união e a vitalidade de quem sabe da
                  importância da luta para a conquista de seus direitos.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/t9W51tJ5Ad0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </v-col>
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex flex-column justify-space-between poem">
            <p>
              <span>A luta</span>
            </p>
            <p>
              <span>Em comunidades muito tranquilas</span>
              <span>Viviam alegres as nossas famílias</span>
              <span>Tínhamos casa e muita união</span>
              <span>Hortas, pastagens, quitanda e pão...</span>
              <span>Igrejas, festas e muito mais</span>
              <span>O patrimônio de Minas Gerais</span>
              <span>Havia ali muita mineração</span>
              <span>Que dava empregos até então</span>
            </p>
            <p>
              <span>Então rompeu uma barragem</span>
              <span>e acabou com toda a paisagem</span>
              <span>Levando embora diversas vidas</span>
              <span>E as que restaram estão atingidas.</span>
            </p>
            <p>
              <span>Pois aquele tempo era presente,</span>
              <span>Agora é passado, luto permanente</span>
              <span>Todos os sonhos a serem vividos</span>
              <span>Estão em suspenso e interrompidos.</span>
            </p>
            <p>
              <span>E como é dura a realidade</span>
              <span>De aprender nova identidade</span>
              <span>Lutar por direitos e reconstrução</span>
              <span>Reassentamento, indenização.</span>
            </p>
            <p>
              <span>Pois não só a lama foi violenta</span>
              <span>Também a justiça, (que) é muito lenta</span>
              <span>E essa disputa pela verdade</span>
              <span>Nos causa angústias e ansiedade</span>
            </p>
            <p>
              <span>Mas para além da devastação</span>
              <span>Tem assembleia, luta, união</span>
              <span>A pauta é reparação integral</span>
              <span>Ajustar a vida pro curso normal</span>
            </p>
            <p>
              <span>Então simbora lutar por justiça</span>
              <span>Que não pode ser cega e nem ser omissa</span>
              <span>Quem quer a vida de novo tem pressa</span>
              <span>Direito sem prática não nos interessa</span>
            </p>
            <p>
              <span>O tempo até pode apagar a lama</span>
              <span>Mas não se esqueçam de Mariana</span>
            </p>
            <p>
              <span><strong>O TEMPO ATÉ PODE APAGAR A LAMA</strong></span>
              <span><strong>MAS NÃO SE ESQUEÇAM DE MARIANA!</strong></span>
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-start">
        <v-col
          cols="12"
          md="4"
          class="d-flex justify-content-center align-center align-self-stretch"
        >
          <div class="inside__card">
            <p><strong>Ficha Técnica</strong></p>
            <p><strong>Letra:</strong> Teresa Chaves</p>

            <p><strong>Produção Musical:</strong> Saulo Fagundes</p>
            <p><strong>Arranjos:</strong> Saulo Fagundes</p>
            <p>
              <strong>Voz:</strong> Débora Letícia, Laura Lelis, Teresa Chaves e
              Saulo Fagundes
            </p>
            <p><strong>Backing Vocal:</strong> Saulo Fagundes</p>
            <p><strong>Bateria:</strong> Saulo Fagundes</p>
            <p><strong>Contrabaixo:</strong> Saulo Fagundes</p>
            <p><strong>Violões:</strong> Saulo Fagundes</p>
            <p><strong>Teclados:</strong> João Gustavo</p>
            <p><strong>Técnico de Gravação:</strong> João Gustavo</p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="section__mb">
      <v-row class="d-flex justify-center align-center pa-4 section__mb">
        <v-col class="d-flex justify-center align-center">
          <v-btn
            :to="{ name: 'conteudos_tematicos_luta_crime' }"
            color="#EDBD43"
          >
            <strong>Conhecer “Não prescrição do crime”</strong>
          </v-btn>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Breadcrumb from "@/layout/Breadcrumb";

export default {
  components: { Card, Breadcrumb },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos /",
          href: "conteudos_tematicos",
        },
        {
          text: "A Luta Continua /",
          href: "conteudos_tematicos_luta_continua",
        },
        {
          text: `${this.$route.meta.title}`,
          href: `${this.$route.name}`,
        },
      ],
    };
  },
};
</script>

<style scoped>
.section__mb {
  padding-bottom: 3em;
}

.luta_continua_background {
  background-image: url("../../assets/conteudos_luta_continua_background.png");
  background-size: cover;
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #f9f7ed;
}

a {
  color: #426e51;
}
.router-link-exact-active {
  cursor: pointer;
  text-decoration: underline;
}

.router-link {
  color: #426e51;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 14px;
}

.poem {
  padding: 2em;
  width: 20em;
}

.poem p span {
  display: block;
  font-size: 0.8em;
  text-align: center;
  font-weight: bold;
  line-height: 30px;
}

.iframe__box {
  position: relative;
  padding-bottom: 56.1%;
  height: 0;
  overflow: hidden;
}

.iframe__box iframe,
.iframe__box object,
.iframe__box embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 600px) {
  .ovx {
    overflow-y: scroll;
    flex-wrap: nowrap;
    overflow-y: auto;

    margin-bottom: 2em;
  }

  .ovx::-webkit-scrollbar {
    width: 10px;
  }

  .ovx::-webkit-scrollbar-track {
    box-shadow: inset 0 1px #426e51;
    border: 6px solid transparent;
    background-clip: content-box;
    width: 180px;
  }
  .ovx::-webkit-scrollbar-thumb {
    background: #426e51;
    border-radius: 10px;
    height: 4px;
    width: 180px;
  }
}
</style>
